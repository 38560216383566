import { useState } from "react"
import styled from "styled-components"

import { SearchFilter } from "src/components/Filter/SearchFilter"
import { HomeGroupAddExistingHomesList } from "src/components/HomeGroups/HomeGroupAddExistingHomesList"
import { HomeGroupBuildingAdditionalInfoDialog } from "src/components/HomeGroups/HomeGroupBuilding/HomeGroupBuildingAdditionalInfoDialog"
import { Pager } from "src/components/Pager/Pager"
import {
  usePostAddExistingUnitsConfirmed,
  usePostAddExistingUnitsInitiated,
  usePostUnitRemovedFromBuilding,
} from "src/data/analytics/queries/homegroupAnalyticsQueries"
import {
  useDeleteHomeGroupHome,
  usePostHomeGroupHomes,
} from "src/data/homeGroups/queries/homeGroupHomeQueries"
import { THomeGroup } from "src/data/homeGroups/types/homeGroupTypes"
import { HOME_LIST_LIMIT } from "src/data/homes/logic/homeConstants"
import { THome, THomeType } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import {
  useFetchHomes,
  usePatchHome,
} from "src/data/organizations/queries/homeQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { MDialog } from "src/ui/Dialog/MDialog"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { ErrorService } from "src/utils/ErrorService"

export function HomeGroupBuildingAddExistingHomesDialog({
  open,
  onClose,
  homeGroup,
}: {
  open: boolean
  onClose: () => void
  homeGroup: THomeGroup
}) {
  // #region Analytics
  const postAddExistingUnitsInitiated = usePostAddExistingUnitsInitiated()
  const postAddExistingUnitsConfirmed = usePostAddExistingUnitsConfirmed()
  const postUnitRemovedFromBuilding = usePostUnitRemovedFromBuilding()
  // #endregion Analytics

  const { t, langKeys } = useTranslate()

  const [offset, setOffset] = useState(0)

  const { org } = useOrganization()
  const [searchString, setSearchString] = useState("")
  const [selectedHome, setSelectedHome] = useState<THome | null>(null)

  const [homeType, setHomeType] = useState<NonNullable<THomeType>>("private")
  const [floor, setFloor] = useState("")
  const [aptNumber, setAptNumber] = useState("")

  const patchHome = usePatchHome()
  const postHomeGroupHomes = usePostHomeGroupHomes()
  const deleteHomeGroupHome = useDeleteHomeGroupHome()

  const fetchHomes = useFetchHomes({
    orgId: org.id,
    filters: { limit: HOME_LIST_LIMIT, offset, name: searchString },
  })

  function handleAddClick(homeId: string) {
    const clickedHome = fetchHomes.data?.homes?.find(
      (home) => home.home_id === homeId
    )
    if (!clickedHome) {
      ErrorService.captureException(
        "Home was not defined when selecting home in home group"
      )
      return
    }

    if (!homeGroup.address) {
      ErrorService.captureException(
        "Home group address was not defined when selecting home in building"
      )
      return
    }

    setSelectedHome(clickedHome)

    postAddExistingUnitsInitiated.mutate()
  }

  async function handleAdd({
    floor,
    aptNumber,
  }: {
    floor: string
    aptNumber: string
  }) {
    if (!selectedHome) {
      ErrorService.captureException(
        "Home was not defined when adding home to home group"
      )
      return
    }

    if (!homeGroup.address) {
      ErrorService.captureException(
        "Home group address was not defined when adding home to building"
      )
      return
    }

    try {
      /**
       * Location is patched by the BE
       */
      await patchHome.mutateAsync({
        orgId: org.id,
        homeId: selectedHome.home_id,
        data: {
          space_use_type: homeType,
          address: {
            street_name1: homeGroup.address.street_name1,
            city: homeGroup.address.city,
            country: homeGroup.address.country,
            post_code: homeGroup.address.post_code,
            floor_number: floor,
            apartment_number: aptNumber || undefined,
          },
        },
      })

      await postHomeGroupHomes.mutateAsync({
        organizationId: org.id,
        homeGroupId: homeGroup.id,
        body: {
          home_id: selectedHome.home_id,
        },
      })

      postAddExistingUnitsConfirmed.mutate()

      setSelectedHome(null)
      setHomeType("private")
      setFloor("")
      setAptNumber("")
    } catch (error) {
      // Errors are handled in the render logic
    }
  }

  function handleRemove(homeId: string) {
    deleteHomeGroupHome.mutate({
      orgId: org.id,
      homeGroupId: homeGroup.id,
      home_id: homeId,
    })

    postUnitRemovedFromBuilding.mutate()
  }

  return (
    <MDialog
      open={open}
      onClose={onClose}
      title={t(langKeys.organizations_homegroup_add_homes_title, {
        name: `'${homeGroup.name}'`,
      })}
      description={t(
        langKeys.home_group_building_add_existing_homes_description
      )}
      error={
        !homeGroup.address ? (
          <div>
            <MText color="unset">
              {t(langKeys.home_group_building_add_existing_home_no_address)}
            </MText>
            <InternalLink
              to={Routes.HomeGroupAddress.location(homeGroup.id).pathname}
            >
              {t(langKeys.home_add_address)}
            </InternalLink>
          </div>
        ) : (
          ""
        )
      }
    >
      <ContentWrapper>
        <SearchFilter
          initialValue={searchString}
          placeholder={t(langKeys.search)}
          onChange={setSearchString}
        />

        <HomeGroupAddExistingHomesList
          homeGroupId={homeGroup.id}
          homes={fetchHomes.data?.homes}
          onAdd={handleAddClick}
          onRemove={handleRemove}
          loadingHomeId={
            postHomeGroupHomes.isLoading
              ? postHomeGroupHomes.variables?.body.home_id
              : undefined
          }
          isLoadingHomes={fetchHomes.isLoading}
          disableAdd={!homeGroup.address}
        />

        <Pager
          limit={HOME_LIST_LIMIT}
          offset={offset}
          setOffset={setOffset}
          totalCount={fetchHomes.data?.paging?.total_count || 0}
        />
      </ContentWrapper>

      <HomeGroupBuildingAdditionalInfoDialog
        open={!!selectedHome && !!homeGroup.address}
        onClose={() => {
          setSelectedHome(null)
          setHomeType("private")
          setFloor("")
          setAptNumber("")

          // Make sure to reset potential errors
          postHomeGroupHomes.reset()
          patchHome.reset()
        }}
        homeName={selectedHome?.name}
        homeGroupName={homeGroup.name}
        homeType={homeType}
        setHomeType={setHomeType}
        floor={floor}
        setFloor={setFloor}
        aptNumber={aptNumber}
        setAptNumber={setAptNumber}
        onSubmit={handleAdd}
        isLoading={postHomeGroupHomes.isLoading || patchHome.isLoading}
        error={
          postHomeGroupHomes.isError || patchHome.isError
            ? t(langKeys.failed_something_went_wrong)
            : ""
        }
      />
    </MDialog>
  )
}

const ContentWrapper = styled.div`
  display: grid;
  gap: ${spacing.M};
  max-height: 600px;
`
