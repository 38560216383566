import styled from "styled-components"

import { THomeType } from "src/data/homes/types/homeTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MDialog } from "src/ui/Dialog/MDialog"
import { MSelect, TMSelectOptions } from "src/ui/MSelect/MSelect"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"

const FORM_ID = "home-group-building-additional-info-dialog"

export function HomeGroupBuildingAdditionalInfoDialog({
  open,
  onClose,
  homeName,
  homeGroupName,
  homeType,
  setHomeType,
  floor,
  setFloor,
  aptNumber,
  setAptNumber,
  onSubmit,
  isLoading,
  error,
}: {
  open: boolean
  onClose: () => void
  homeName?: string
  homeGroupName: string
  homeType: NonNullable<THomeType>
  setHomeType: (homeType: NonNullable<THomeType>) => void
  floor: string
  setFloor: (floor: string) => void
  aptNumber: string
  setAptNumber: (aptNumber: string) => void
  onSubmit: (args: {
    homeType: THomeType
    floor: string
    aptNumber: string
  }) => void
  isLoading: boolean
  error: string | null
}) {
  const { t, langKeys } = useTranslate()

  return (
    <MDialog
      title={t(langKeys.home_group_add_existing_home_title, {
        homeGroupName,
        homeName,
      })}
      description={t(langKeys.home_group_add_existing_home_description)}
      formId={FORM_ID}
      open={open}
      onClose={onClose}
      loading={isLoading}
      error={error}
    >
      <Form
        id={FORM_ID}
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit({
            homeType,
            floor,
            aptNumber,
          })
        }}
      >
        <MSelect
          label={t(langKeys.home_type)}
          value={homeType}
          options={
            [
              {
                label: t(
                  langKeys.home_group_building_create_home_private_area_title
                ),
                value: "private",
              },
              {
                label: t(
                  langKeys.home_group_building_create_home_communal_area_title
                ),
                value: "communal",
              },
            ] satisfies TMSelectOptions<NonNullable<THomeType>>
          }
          onChange={(selectedValue) => {
            setHomeType(selectedValue as NonNullable<THomeType>)

            if (selectedValue === "communal") {
              setAptNumber("")
            }
          }}
          required
          requiredIndicator
        />

        <MTextField
          label={t(langKeys.home_group_building_create_home_floor)}
          value={floor}
          onChange={(value) => setFloor(value)}
          required
          requiredIndicator
        />

        {homeType === "private" && (
          <MTextField
            label={t(langKeys.home_group_building_create_home_apt_number)}
            value={aptNumber}
            onChange={(value) => setAptNumber(value)}
            required
            requiredIndicator
          />
        )}
      </Form>
    </MDialog>
  )
}

const Form = styled.form`
  display: grid;
  gap: ${spacing.M};
`
